<div class="logo" [ngClass]="{'logo-inline': mode==='vertical'}">
  <a routerLink="/layout">
<!--    <img src="https://ng.ant.design/assets/img/logo.svg"/>-->
    <!--    <img src="assets/imgs/left_top_logo.png" />-->
    <img src="../../../assets/imgs/logoleft.png" height="50" width="100"/>
  </a>
</div>
<nzo-levels-menu class="levels-menu" [menus]="menus" theme="dark" [mode]="mode" [selected]="selectedUrl"
                 [inlineCollapsed]="collapsed" [class.nzo-menu]="mode==='vertical'">
</nzo-levels-menu>
