import {Injectable} from '@angular/core';
import {of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class Routes {
  private headerRouteList: Array<any> = [
    // {
    //   groupId: 'Mgr',
    //   groupName: '管理中心',
    //   permissionCode: 'Mgr',
    // }, {
    //   groupId: 'Mgr.Library',
    //   groupName: '资料库',
    //   permissionCode: 'Mgr.Library',
    // }
  ];

  // private sideRouteList: Array<any> = [
  //   {
  //     groupId: 'Mgr',
  //     name: '基本信息',
  //     permissionCode: 'Mgr.BasicInfo',
  //     children: [{
  //       name: '产品管理',
  //       router: '/layout/basicInfo/product',
  //       permissionCode: 'Mgr.BasicInfo.Product',
  //     }, {
  //       name: '字典字段设置',
  //       router: '/layout/basicInfo/dictionary',
  //       permissionCode: 'Mgr.BasicInfo.Dictionary',
  //     }, {
  //       name: '半天事务管理',
  //       router: '/layout/basicInfo/halfDay',
  //       permissionCode: 'Mgr.BasicInfo.HalfDay',
  //     }, {
  //       name: '行政区域管理',
  //       router: '/layout/basicInfo/area',
  //       permissionCode: 'Mgr.BasicInfo.Area',
  //     }, {
  //       name: '拜访传递信息',
  //       router: '/layout/basicInfo/callMessage',
  //       permissionCode: 'Mgr.BasicInfo.CallMessage',
  //     }, {
  //       name: '角色定义',
  //       router: '/layout/basicInfo/role',
  //       permissionCode: 'Mgr.BasicInfo.Role',
  //     }, {
  //       name: '角色与权限',
  //       router: '/layout/basicInfo/permissionsRole',
  //       permissionCode: 'Mgr.BasicInfo.PermissionsRole',
  //     }, {
  //       name: '科室管理',
  //       router: '/layout/basicInfo/dept',
  //       permissionCode: 'Mgr.BasicInfo.Dept',
  //     }
  //     ]
  //   },
  // ];

  private sideRouteList: Array<any> = [
    {
      name: '拜访',
      router: '/layout/distributor/distributor-call',
      permissionCode: 'Mgr.Distributor.Call',
    }, {
      name: '经销商出货',
      router: '/layout/distributor/distributor-shipment',
      permissionCode: 'Mgr.Distributor.Shipment',
    }, {
      name: '经销商库存',
      router: '/layout/distributor/distributor-stock',
      permissionCode: 'Mgr.Distributor.Stock'
    }
  ];

  public getHeaderRoutes() {
    return of(this.headerRouteList);
  }

  public getSideRoutes() {
    return of(this.sideRouteList);
  }

}
