import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AccountService } from "../services/account.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ActivatedRoute } from "@angular/router";
import { CryptoService } from "../providers/crypto.service";

@Component({
  selector: "nzo-changepwd",
  templateUrl: "./changepwd.component.html",
  styleUrls: ["./changepwd.component.less"],
})
export class ChangePwdComponent implements OnInit {
  isLoading: boolean;
  validateForm: UntypedFormGroup;
  password: any;
  userName: any;
  newPassword: string;
  newPasswordConfirm: string;
  oldPwdImgSrc = "assets/imgs/eye_hide.png";
  oldPwdTxtType = "password";
  newPwdImgSrc = "assets/imgs/eye_hide.png";
  newPwdTxtType = "password";
  newPwdConfirmImgSrc = "assets/imgs/eye_hide.png";
  newPwdConfirmTxtType = "password";

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    public http: HttpClient,
    private accountService: AccountService,
    public activatedRoute: ActivatedRoute,
    private NzNotificationService: NzNotificationService,
    private nzMessageService: NzMessageService,
    private cryptoService: CryptoService
  ) {}

  //点击取消，回到登录
  goback() {
    this.router.navigate(["login"]);
  }
  changePwd() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (!this.validateForm.valid) {
      return;
    }
    //更改密码
    this.accountService
      .ChangeDmsPwd({
        loginName: this.userName,
        oldPassword: this.cryptoService.rsaPublicEncrypt(this.password),
        newPassword: this.cryptoService.rsaPublicEncrypt(this.newPassword),
      })
      .subscribe((r: any) => {
        console.log("返回结果", r);
        if (r.isSuccess) {
          this.NzNotificationService.success("提示", "密码更改成功！！", {
            nzDuration: 2000,
          });
          this.router.navigate(["login"]);
          //this.validateForm.reset();
        } else {
          this.nzMessageService.warning(r.errorMsg);
        }
      });
  }

  showOrHideOldPwd() {
    if ("text" == this.oldPwdTxtType) {
      this.oldPwdImgSrc = "assets/imgs/eye_hide.png";
      this.oldPwdTxtType = "password";
    } else {
      this.oldPwdImgSrc = "assets/imgs/eye_show.png";
      this.oldPwdTxtType = "text";
    }
  }
  showOrHideNewPwd() {
    if ("text" == this.newPwdTxtType) {
      this.newPwdImgSrc = "assets/imgs/eye_hide.png";
      this.newPwdTxtType = "password";
    } else {
      this.newPwdImgSrc = "assets/imgs/eye_show.png";
      this.newPwdTxtType = "text";
    }
  }
  showOrHideNewConfirmPwd() {
    if ("text" == this.newPwdConfirmTxtType) {
      this.newPwdConfirmImgSrc = "assets/imgs/eye_hide.png";
      this.newPwdConfirmTxtType = "password";
    } else {
      this.newPwdConfirmImgSrc = "assets/imgs/eye_show.png";
      this.newPwdConfirmTxtType = "text";
    }
  }

  //2次密码不一致校验
  confirmationValidator = (
    control: UntypedFormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.newPassword.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  //密码规则校验：
  pwdCheck = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else {
      var reg =
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+|~\-=\\`\{}[\]:;'<>\""?,./])[0-9a-zA-Z!@#$%^&*()_+|~\-=\\`\{}[\]:;'<>\""?,./]{8,18}$/;
      if (!reg.test(control.value)) {
        return { confirm: true, error: true };
      }
    }
    return {};
  };
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      newPassword: [null, [Validators.required, this.pwdCheck]],
      newPasswordConfirm: [
        null,
        [Validators.required, this.confirmationValidator],
      ],
    });
  }
}
