import {Injectable} from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(private nzNotificationService: NzNotificationService,
              private nzMessageService: NzMessageService,
              private nzModalService: NzModalService) {
  }

  // 成功提示语
  success(message) {
    this.nzNotificationService.success('提示', message, {
      nzDuration: 1500
    });
  }

  // 错误提示语
  error(message) {
    this.nzNotificationService.error('提示', message, {
      nzDuration: 3000
    });
  }

  // 警告提示语
  warning(message) {
    this.nzNotificationService.warning('提示', message, {
      nzDuration: 3000
    });
  }

  // 信息提示语
  info(message) {
    this.nzNotificationService.info('提示', message, {
      nzDuration: 3000
    });
  }

  toastSuccess(message) {
    this.nzMessageService.success(message, {
      nzDuration: 2500
    });
  }

  toastError(message) {
    this.nzMessageService.error(message, {
      nzDuration: 3000
    });
  }

  toastWarning(message) {
    this.nzMessageService.warning(message, {
      nzDuration: 3000
    });
  }

  toastInfo(message) {
    this.nzMessageService.info(message, {
      nzDuration: 3000
    });
  }


  // 确定对话框
  confirmNotice = (nzTitle, nzContent) => new Promise(resolve => {
    this.nzModalService.confirm({
      nzTitle,
      nzContent,
      nzCancelText: '取消',
      nzOnOk: () => {
        resolve(true);
      },
      nzOnCancel: () => {
        resolve(false);
      }
    });
  });

}
