import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {nzoLayoutService} from '../nzo-layout.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {setting} from '../../../setting';
import {Routes} from '../../config/routes';

export const GLOBAL_LAYOUT_ID = 'main';

@Component({
  selector: 'nzo-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: [
    './basic-layout.component.less',
    '../navigation-bar/navigation-bar.component.less'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicLayoutComponent implements OnInit {
  setting = setting;
  isCollapsed = false;
  menus: any[];
  currentUrl: string;
  @ViewChild('header') header: ElementRef;
  allGroupMenu: any = [];
  headerNavList = [];
  currentSelectedGroup: any;

  private defaultSideMenu = '明细导出';
  private defaultHeaderGroup = '';

  constructor(private router: Router,
              private cdr: ChangeDetectorRef,
              private layout: nzoLayoutService,
              private rts: Routes) {
    this.router.events.pipe(
      filter(event => (event instanceof NavigationEnd)),
    ).subscribe((event: any) => {
      event.url = event.url.replace(/^(.+)\?.+/, '$1');
      if (this.currentUrl !== event.url) {
        this.currentUrl = event.url;
      }
      this.layout.setCurrentRouter(event.url);
    });
    this.layout.layoutChange(GLOBAL_LAYOUT_ID).subscribe(data => {
      this.setting.layoutMode = data.mode || this.setting.layoutMode;
      this.isCollapsed = data.collapsed || this.isCollapsed;
    });
  }

  // 默认初始页面
  setDefautePage(pageName: string) {
    const exportPage: any = this.menus.filter(m => m.name === pageName)[0];
    if (exportPage) {
      exportPage.open = true;
      exportPage.children[0].selected = true;
      exportPage.children[0].open = true;
      this.cdr.detectChanges();
      this.layout.setCurrentRouter(exportPage.children[0].router);
      this.router.navigate([exportPage.children[0].router]);
    } else {
      const pageSet = this.menus[0];
      if (pageSet) {
        pageSet.open = true;
        if (pageSet.children[0]) {
          pageSet.children[0].selected = true;
          this.cdr.detectChanges();
          this.layout.setCurrentRouter(pageSet.children[0].router);
          this.router.navigate([pageSet.children[0].router]);
        }
      }
    }
  }

  setHeaderPage() {
    if (this.menus[0] && this.menus[0].children[0]) {
      const url: any = this.menus[0].children[0].router;
      this.menus[0].children[0].selected = true;
      this.menus[0].open = true;
      if (this.currentUrl !== url) {
        this.currentUrl = url;
      }
      this.layout.setCurrentRouter(url);
      this.router.navigate([url]);
    }
  }

  // 点击头部
  clickHeader(ev) {
    // 头页面有数据
    this.currentSelectedGroup = ev;
    this.menus = this.allGroupMenu.filter(s => this.currentSelectedGroup.groupId === s.groupId);
    if (this.currentSelectedGroup.GroupName === this.defaultHeaderGroup) {
      this.setHeaderPage();
    } else {
      this.setDefautePage(this.defaultSideMenu);
    }
    this.layout.cacheMenus(this.menus);
  }

  ngOnInit() {
    this.rts.getSideRoutes().subscribe((sideList: Array<any>) => {
      console.log(sideList);
      this.allGroupMenu = sideList;
      this.menus = this.allGroupMenu;
      // this.setDefautePage(this.defaultSideMenu);
      this.cdr.markForCheck();
      this.layout.cacheMenus(this.menus);
    });
    // this.rts.getHeaderRoutes().subscribe(heardList => {
    //   this.headerNavList = heardList;
    //   if (heardList && heardList[0]) {
    //     this.currentSelectedGroup = heardList[0];
    //     this.rts.getSideRoutes().subscribe((sideList: Array<any>) => {
    //       this.allGroupMenu = sideList;
    //      this.menus = this.allGroupMenu.filter(s => this.currentSelectedGroup.groupId === s.groupId);
    //       console.log(this.menus);
    //       this.setDefautePage(this.defaultSideMenu);
    //       this.cdr.markForCheck();
    //       this.layout.cacheMenus(this.menus);
    //     });
    //   }
    // });
  }

  onChangeCollapsed(event: any) {
    this.layout.dispatch({collapsed: event, layoutId: GLOBAL_LAYOUT_ID});
  }
}
