import { Inject, Injectable } from "@angular/core";
import { ApiService, API_SERVICE } from "ng-upgrade-api";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class InformedConsent {
  constructor(@Inject(API_SERVICE) private apiService: ApiService) {}

  GetActiveInformedConsent(model: any): Observable<any> {
    return this.apiService.postJson(`DMSInformed/GetActiveInformedConsent`, {
      body: model,
    });
  }

  // 保存知情同意书
  SaveUserAgreeRecord(model: any): Observable<any> {
    return this.apiService.postJson(`DMSInformed/SaveUserAgreeRecord`, {
      body: model,
    });
  }
}
