import {Inject, Injectable} from '@angular/core';
import {ApiService, API_SERVICE} from 'ng-upgrade-api';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasicService {

  constructor(@Inject(API_SERVICE) private apiService: ApiService) {
  }

  /***
   * 例子,按此标准来
   */
  OKPermissionController(): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson(`Test1/OKPermissionController`);
  }


  // 字典项左侧菜单列表
  GetSystemType(): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`SystemTypeSetting/GetSystemType`);
  }

  // 字典项左侧菜单列表
  GetSystemTypeByTag(moduleCode:string,tag:string): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`SystemTypeSetting/GetSystemTypeByTag/${moduleCode}/${tag}`);
  }

  // 保存字典
  SaveSystemType(model: any): Observable<any> {
    // @ts-ignore
    return this.apiService.postJson(`SystemTypeSetting/SaveSystemType`, {
        body: model
    });
  }

  // 获取字典列表
  GetSystemTypeGridInfo(moduleCode: string): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`api/SystemTypeSetting/GetSystemTypeGridInfo/${moduleCode}`, {});
  }

  // 获取字典项详情
  GetSystemTypeDetail(typeCode: string): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`api/SystemTypeSetting/GetSystemTypeDetail/${typeCode}`, {});
  }

  // 删除字典项
  DeleteSystemType(model: any): Observable<any> {
    // @ts-ignore
    return this.apiService.postJson(`SystemTypeSetting/DeleteSystemType`, {
        body: model
    });
  }

  // 半天事务组
  GetHalfdayType(): Observable<any> {
    // @ts-ignore
    return this.apiService.postJson(`api/TerritorySetting/GetHalfdayType`, {});
  }

  // 半天事务大类明细
  GetSystemTypeInfo(typeId: string): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`api/TerritorySetting/GetSystemTypeInfo/${typeId}`, {});
  }

  // 保存半天事务大类/半天事务类别
  SaveHalfdayType(model: any): Observable<any> {
    // @ts-ignore
    return this.apiService.postJson(`api/TerritorySetting/SaveHalfdayType`, {
      body: model
    });
  }

  // 获取半天事务类别列表
  GetHalfdayTypeGrid(typeCode: string): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`api/TerritorySetting/GetHalfdayTypeGrid/${typeCode}`, {});
  }

  // 半天事务类别明细
  HalfdayTypeTypeForm(typeCode: string, typeId: string): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`api/TerritorySetting/HalfdayTypeTypeForm/${typeCode}/${typeId}`, {});
  }

  // 删除半天事务
  DeleteHalfdayTypeTypeForm(typeCode: string, typeId: string): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`api/TerritorySetting/DeleteHalfdayTypeTypeForm/${typeId}`, {});
  }


  // 获取管理员参数
  GetAdminInfo(): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`AdminSetting/GetAdminInfo`);
  }



  // 修改管理员参数
  SetAdminInfo(code, value): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`AdminSetting/SetAdminInfo/${code}/${value}`);
  }

  // 修改角色列表
  GetRoleList(): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`SystemTypeSetting/GetRoleList`);
  }


  // 获得角色权限
  GetPermissionsList(roleID): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`SystemTypeSetting/GetPermissionsList/${roleID}`);
  }


  // 修改角色权限
  SetRolePermissions(roleID, permissions, checked): Observable<any> {
    // @ts-ignore
    return this.apiService.getJson<any>(`SystemTypeSetting/SetRolePermissions/${roleID}/${permissions}/${checked}`);
  }

  getRSAPubKey(): Observable<any> {
    return this.apiService.getJson(`System/GetRSAPubKey`);
  }

}
