<div class="login-container" style="">
  <div class="title">
    <!--    <span class="word-title">LOGO</span>-->
    <img src="assets/imgs/LogoAmd.png" style="width: 200px;height: 100px;" />
  </div>
  <form nz-form [formGroup]="validateForm" class="login-form">
    <!-- 更改密码-->
    <div>
      <nz-form-item>
        <nz-form-control nzErrorTip="请输入用户名!">
          <nz-input-group>
            <img src="assets/imgs/login_account.png" alt="" width="15"
              style="position: absolute;left: 10px;top:10px;z-index: 9" />
            <input class="input-class" type="text" nz-input [(ngModel)]="userName" style="padding-left: 35px"
              formControlName="userName" placeholder="用户名" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="请输入旧密码!">
          <nz-input-group>
            <img src="assets/imgs/login_psw.png" alt="" width="15"
              style="position: absolute;left: 10px;top:10px;z-index: 9" />
            <input class="input-class" [type]="oldPwdTxtType" nz-input [(ngModel)]="password" style="padding-left: 35px"
              formControlName="password" placeholder="旧密码" />
            <img [src]="oldPwdImgSrc" alt="" width="15" style="position: absolute;right: 10px;top:10px;z-index: 9"
              (click)="showOrHideOldPwd()" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="必须包含字母、数字、特殊字符,长度8-18">
          <nz-input-group>
            <img src="assets/imgs/login_psw.png" alt="" width="15"
              style="position: absolute;left: 10px;top:10px;z-index: 9" />
            <input class="input-class" [type]="newPwdTxtType" nz-input [(ngModel)]="newPassword"
              style="padding-left: 35px" formControlName="newPassword" placeholder="新密码" />
            <img [src]="newPwdImgSrc" alt="" width="15" style="position: absolute;right: 10px;top:10px;z-index: 9"
              (click)="showOrHideNewPwd()" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="两次密码不一致!">
          <nz-input-group>
            <img src="assets/imgs/login_psw.png" alt="" width="15"
              style="position: absolute;left: 10px;top:10px;z-index: 9" />
            <input class="input-class" [type]="newPwdConfirmTxtType" nz-input [(ngModel)]="newPasswordConfirm"
              style="padding-left: 35px" formControlName="newPasswordConfirm" placeholder="确认新密码" />
            <img [src]="newPwdConfirmImgSrc" alt="" width="15"
              style="position: absolute;right: 10px;top:10px;z-index: 9" (click)="showOrHideNewConfirmPwd()" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <div style="display: flex;color: white;">
            <button nz-button class="login-form-button" [nzType]="'primary'" [nzShape]="'round'" (click)="goback()">
              取消
            </button>
            <button nz-button class="login-form-button" [nzType]="'primary'" [nzShape]="'round'" (click)="changePwd()">
              更改密码
            </button>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>

  </form>
  <div class="copyright-word">
    2010-2022 &#64;上海太美星环数字科技有限公司 版权所有沪ICP备 11017254号-8
  </div>
</div>
