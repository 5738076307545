import {Injectable} from '@angular/core';
import {
  EVENT_TOPIC_API_COMPLETED,
  EVENT_TOPIC_API_ERROR,
  EVENT_TOPIC_API_REQUESTING,
  STATUS_NAME_API_REQUESTING
} from 'ng-upgrade-api';
import {GeneralService} from 'ng-upgrade-common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {Router} from '@angular/router';
import {GlobalVariable} from '../config/variable';


@Injectable({
  providedIn: 'root'
})
export class ApinfoService {
  globalVariable = GlobalVariable;

  constructor(private generalService: GeneralService,
              public nzNotificationService: NzNotificationService,
              public nzMessageService: NzMessageService,
              private router: Router) {
  }

  public getApiInfo() {
    this.generalService.subscribeEvent([EVENT_TOPIC_API_ERROR], (err) => {
      console.log('err', err)
      if (err && err.data) {
        this.globalVariable.loadingIsShow = false;
        this.collectFailedHttp(err.data);
      }
    });
    this.generalService.eventWithStatus(
      [EVENT_TOPIC_API_REQUESTING, EVENT_TOPIC_API_COMPLETED],
      STATUS_NAME_API_REQUESTING,
      (res) => {
        console.log('res', res)
        if (res) {
          this.globalVariable.loadingIsShow = res.length > 0;
        }
      });
  }

  // 403跳到登录页面
  public permissToLogin(httpStatus: any, message: string) {
    // tslint:disable-next-line:triple-equals
    if (httpStatus == 401) {
      this.router.navigate(['/login']).then(() => {
        sessionStorage.clear();
        localStorage.clear();
        this.nzNotificationService.error('提示', message,
          {
            nzDuration: 4000
          });
      });
    } else {
      this.nzNotificationService.error('提示', message,
        {
          nzDuration: 4000
        });
    }
  }


  noticeMessage(err: any) {
    if (err.code == 'ToChangePwd') {
      this.nzNotificationService.error('提示', err.message ? err.message : '与服务器连接出现问题', {
        nzDuration: 4000
      });
      this.router.navigate(['changepwd'])
    } else {
      this.nzNotificationService.error('提示', err.message ? err.message : '与服务器连接出现问题',
        {
          nzDuration: 4000
        });
    }
  }

  public collectFailedHttp(err: any) {
    switch (err.httpStatus) {
      case 401:
        // 登录失败
        this.permissToLogin(401, '身份信息已过期,请重新登录');
        break;
      case 403:
        this.permissToLogin(403, '您没有访问权限,请联系系统管理员');
        break;
      default:
        this.noticeMessage(err);//err.message ? err.message : '与服务器连接出现问题'
    }
  }

}
