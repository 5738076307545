<div style="height: 50vh;overflow: scroll;">
  <div [innerHTML]="paraContent"></div>
  <div class="check-box">
    <div *ngFor="let item of buttons" class="check-item">
      <input type="checkbox" [name]="item.sort" [(ngModel)]="item.isConsent" (click)="onClick(item)" />
      <span>
        <!-- buttonText -->
        {{item.buttonText}}

      </span>
    </div>
  </div>

  <!-- 测试div -->
  <div id="test" style="height:10px"></div>
</div>
<!-- <div *nzModalFooter>
    <button nz-button nzType="default" (click)="destroyModal()">Custom Callback</button>
    <button nz-button nzType="primary" (click)="destroyModal()">Custom Submit</button>
</div> -->