import {Component, Input, OnInit} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import {Router} from '@angular/router';
import {GeneralService} from 'ng-upgrade-common';
import {AccountService} from '../../services/account.service';

@Component({
  selector: 'nzo-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.less'],
})
export class GlobalHeaderComponent implements OnInit {
  @Input() theme: 'dark' | 'light' = 'dark';
  // @Input() theme = 'dark';
  user = {avatar: 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png'};
  currentStaffInfo: any;

  constructor(private nzModalService: NzModalService,
              private router: Router,
              private generalService: GeneralService,
              private accountService: AccountService) {
  }

  // 退出登录
  logout() {
    this.nzModalService.confirm({
      nzTitle: '确定要退出登录吗？',
      nzMaskClosable: true,
      nzOnOk: () => {
        this.accountService.Logout().subscribe(_ => {
          this.router.navigate(['login']);
          localStorage.clear();
          sessionStorage.clear();
        })
      }
    });
  }
  //修改密码
  edit() {
    this.router.navigate(['changepwd']);
  }

  ngOnInit() {
    this.currentStaffInfo = this.generalService.getAccountInfo();
  }

}
