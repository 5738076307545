<ul class="nzo-global-header nzo-global-header-{{theme}}">
  <!--  <li>-->
  <!--    <span><nz-badge [nzCount]=" 5 "><i nz-icon nzType="bell"></i></nz-badge></span>-->
  <!--  </li>-->
  <li>
    <span nz-dropdown [nzDropdownMenu]="global" nzOverlayClassName="nzo-i18n-overlay">
      <nz-avatar [nzSize]="'small'" [nzSrc]="user.avatar"></nz-avatar>
      <span class="nzo-pl-xs">{{currentStaffInfo?.displayName}}</span>
    </span>
    <nz-dropdown-menu #global>
      <ul nz-menu>
        <!--        <li nz-menu-item>-->
        <!--          <i nz-icon nzType="user"></i>-->
        <!--          <span>个人中心</span>-->
        <!--        </li>-->
        <!--        <li nz-menu-item>-->
        <!--          <i nz-icon nzType="setting"></i>-->
        <!--          <span>个人设置</span>-->
        <!--        </li>-->
        <li nz-menu-item (click)="edit()">
          <i nz-icon nzType="edit"></i>
          <span>更改密码</span>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-item (click)="logout()">
          <i nz-icon nzType="logout"></i>
          <span>退出登录</span>
        </li>
      </ul>
    </nz-dropdown-menu>
  </li>
  <!--  <li>-->
  <!--    <span nz-dropdown [nzDropdownMenu]="i18n" nzOverlayClassName="nzo-i18n-overlay" nzPlacement="bottomRight">-->
  <!--      <span><i nz-icon nzType="global"></i></span>-->
  <!--    </span>-->
  <!--    <nz-dropdown-menu #i18n>-->
  <!--      <ul nz-menu>-->
  <!--        <li nz-menu-item>🇨🇳&nbsp;简体中文</li>-->
  <!--        <li nz-menu-item>🇺🇸&nbsp;English</li>-->
  <!--      </ul>-->
  <!--    </nz-dropdown-menu>-->
  <!--  </li>-->
</ul>
