
export const decorateEnv = (env) => {
    env.build.buildDate = '20240702030111';
    env.build.buildDateEpoch = 1719889271200;
    env.build.buildRevision = '56e37e25da18c67da1640451c7a699d54a1326c9';
    env.build.project = 'abbottamd';
    env.build.part = 'dms';
    env.build.deployment = 'uat';
    env.build.viewport = 'pc';
    env.apiRoot = 'https://amdsfe-api-s.abbott.com.cn';
    env.production = 'false';
    env.variables.mapApiKey = 'ee95e52bf08006f63fd29bcfbcf21df0';
};
