import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BasicLayoutComponent} from './layout/basic-layout/basic-layout.component';
import {LoginComponent} from './login/login.component';
import {ChangePwdComponent} from './changepwd/changepwd.component';
import {TestComponent} from './features/test/test.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'changepwd',
    component: ChangePwdComponent
  },
  {
    path: 'layout', component: BasicLayoutComponent, children: [
      {path: '', redirectTo: 'distributor', pathMatch: 'full'},
      {
        path: 'person',
        loadChildren: () => import('./pages/person-area/person-area.module').then(m => m.PersonAreaModule)
      },
      {
        path: 'client',
        loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule)
      },
      {
        path: 'roles',
        loadChildren: () => import('./pages/roles/roles.module').then(m => m.RolesModule)
      },
      {
        path: 'developer',
        loadChildren: () => import('./pages/developer/developer.module').then(m => m.DeveloperModule)
      },
      {
        path: 'basicInfo',
        loadChildren: () => import('./pages/basic-info/basic-info.module').then(m => m.BasicInfoModule)
      },
      {
        path: 'library',
        loadChildren: () => import('./pages/library/library.module').then(m => m.LibraryModule)
      },
      {
        path: 'photos',
        loadChildren: () => import('./pages/photos/photos.module').then(m => m.PhotosModule)
      },
      {
        path: 'detailExport',
        loadChildren: () => import('./pages/detail-export/detail-export.module').then(m => m.DetailExportModule)
      },
      {
        path: 'surey',
        loadChildren: () => import('./pages/surey/surey.module').then(m => m.SureyModule)
      },
      {
        path: 'notice',
        loadChildren: () => import('./pages/notice/notice.module').then(m => m.NoticeModule)
      },
      {
        path: 'paraSetting',
        loadChildren: () => import('./pages/para-setting/para-setting.module').then(m => m.ParaSettingModule)
      },
      {
        path: 'routeLine',
        loadChildren: () => import('./pages/route-line/route-line.module').then(m => m.RouteLineModule)
      },
      {
        path: 'distributor',
        loadChildren: () => import('./pages/distributor/distributor.module').then(m => m.DistributorModule)
      }
    ]
  },
  {
    path: 'test',
    component: TestComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
