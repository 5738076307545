import { BuildInfo } from 'ng-upgrade-basis';

const env = {
  build: new BuildInfo(),
  apiRoot: '/api',
  variables: null,
  production: null,
  hmr: null
};
env.build.project = 'demo';
env.build.part = 'mgr';
env.variables = {
  mapApiKey: 'ee95e52bf08006f63fd29bcfbcf21df0'
}

export const buildDefaultEnvironment = () => env;

