<!-- nz-menu don't support dynamic creation of multi-level menus -->
<ul nz-menu [nzTheme]="'dark'" [nzMode]="mode==='vertical'?'inline':mode" [nzInlineCollapsed]="inlineCollapsed"
    class="ul-div">
  <nz-spin [nzSpinning]="false">
    <ng-container *ngFor="let menu of selectedMenus">
      <ng-container *xskPermShow="menu.permissionCode">
      <li nz-submenu [nzOpen]="menu.open" *ngIf="menu.children && menu.children.length > 0 && (menu.permissionCode | hasPerm);else menuItem">
        <span title><i nz-icon *ngIf="menu.icon" [nzType]="menu.icon"></i><span>{{menu.name}}</span></span>
        <ul *ngIf="menu.children && menu.children.length > 0 && (menu.permissionCode | hasPerm)">
          <ng-container *ngFor="let menu1 of menu.children">
            <ng-container *xskPermShow="menu1.permissionCode">
              <li nz-submenu [nzOpen]="menu1.open" *ngIf="menu1.children && menu1.children.length > 0 && (menu1.permissionCode | hasPerm);else menuItem1">
                <span title><i nz-icon *ngIf="menu1.icon" [nzType]="menu1.icon"></i><span>{{menu1.name}}</span></span>
                <ul *ngIf="menu1.children && menu1.children.length > 0 && (menu1.permissionCode | hasPerm)">
                  <ng-container *ngFor="let menu2 of menu1.children">
                    <ng-container *xskPermShow="menu2.permissionCode">
                      <li nz-submenu [nzOpen]="menu2.open" *ngIf="menu2.children && menu2.children.length > 0 && (menu2.permissionCode | hasPerm);else menuItem2">
                        <span title><i nz-icon *ngIf="menu2.icon"  [nzType]="menu2.icon"></i><span>{{menu2.name}}</span></span>
                        <ul *ngIf="menu2.children && menu2.children.length > 0 && (menu2.permissionCode | hasPerm)">
                          <ng-container *ngFor="let menu3 of menu2.children">
                            <ng-container *xskPermShow="menu3.permissionCode">
                               <li nz-submenu [nzOpen]="menu3.open" *ngIf="menu3.children && menu3.children.length > 0 && (menu3.permissionCode | hasPerm);else menuItem3">
                              <span title><i nz-icon *ngIf="menu3.icon" [nzType]="menu3.icon"></i><span>{{menu3.name}}</span></span>
                              <ul *ngIf="menu3.children && menu3.children.length > 0 && (menu3.permissionCode | hasPerm)">
                                <ng-container *ngFor="let menu4 of menu3.children">
                                  <ng-container *xskPermShow="menu4.permissionCode">
                                    <li nz-submenu [nzOpen]="menu4.open" *ngIf="menu4.children && menu4.children.length > 0 && (menu4.permissionCode | hasPerm);else menuItem4">
                                      <span title><i nz-icon *ngIf="menu4.icon" [nzType]="menu4.icon"></i><span>{{menu4.name}}</span></span>
                                      <ul *ngIf="menu4.children && menu4.children.length > 0 && (menu4.permissionCode | hasPerm)">
                                        <ng-container *ngFor="let menu5 of menu3.children">
                                          <li nz-menu-item (click)="onGoPage(menu5)" [nzSelected]="menu5.selected">
                                            <span><i nz-icon *ngIf="menu5.icon" [nzType]="menu5.icon"></i><span>{{menu5.name}}</span></span>
                                          </li>
                                        </ng-container>
                                      </ul>
                                    </li>
                                    <ng-template #menuItem4>
                                      <li nz-menu-item (click)="onGoPage(menu4)" [nzSelected]="menu4.selected">
                                        <span><i nz-icon *ngIf="menu4.icon" [nzType]="menu4.icon"></i><span>{{menu4.name}}</span></span>
                                      </li>
                                    </ng-template>
                                  </ng-container>
                                </ng-container>
                              </ul>
                            </li>
                               <ng-template #menuItem3>
                              <li nz-menu-item (click)="onGoPage(menu3)" [nzSelected]="menu3.selected">
                          <span><i nz-icon *ngIf="menu3.icon"
                                   [nzType]="menu3.icon"></i><span>{{menu3.name}}</span></span>
                              </li>
                            </ng-template>
                            </ng-container>
                          </ng-container>
                        </ul>
                      </li>
                      <ng-template #menuItem2>
                        <li nz-menu-item (click)="onGoPage(menu2)" [nzSelected]="menu2.selected">
                            <span><i nz-icon *ngIf="menu2.icon"
                                     [nzType]="menu2.icon"></i><span>{{menu2.name}}</span></span>
                        </li>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </ul>
              </li>
              <ng-template #menuItem1>
                <li nz-menu-item (click)="onGoPage(menu1)" [nzSelected]="menu1.selected">
                  <span><i nz-icon *ngIf="menu1.icon" [nzType]="menu1.icon"></i><span>{{menu1.name}}</span></span>
                </li>
              </ng-template>
            </ng-container>
          </ng-container>
        </ul>
      </li>
      <ng-template #menuItem>
        <li nz-menu-item (click)="onGoPage(menu)" [nzSelected]="menu.selected">
          <span><i nz-icon *ngIf="menu.icon" [nzType]="menu.icon"></i><span>{{menu.name}}</span></span>
        </li>
      </ng-template>
     </ng-container>
    </ng-container>
    <li style="width: 100%;height: 45px">&nbsp;</li>
  </nz-spin>
</ul>
