import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChangePwdComponent} from './changepwd.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [ChangePwdComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    ReactiveFormsModule,
    NzIconModule,
    NzSpinModule,
    NzAlertModule,
  ],
  providers: [NzMessageService]
})
export class ChangePwdModule {
}
