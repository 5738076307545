
import {buildDefaultEnvironment} from './environment.default';
import {decorateEnv} from './environment.decorator';
import {postProcessEnv} from './environment.postprocess';

const environment = buildDefaultEnvironment();
decorateEnv(environment);
postProcessEnv(environment);

export {environment};
