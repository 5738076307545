<nz-layout class="layout">
  <nz-header class="nzo-top-header" *ngIf="setting.layoutMode==='horizontal'">
    <div #header>
      <nzo-navigation-bar
        class="nzo-top-nav"
        [selectedUrl]="currentUrl"
        [menus]="menus"
        [collapsed]="isCollapsed"
        mode="horizontal">
      </nzo-navigation-bar>
      <nzo-global-header theme="dark"></nzo-global-header>
    </div>
  </nz-header>

  <ng-template [ngIf]="!setting.layoutMode || setting.layoutMode==='vertical'">
    <nz-sider nzWidth="200px" nzCollapsible
              [(nzCollapsed)]="isCollapsed"
              [nzBreakpoint]="'lg'"
              [nzTrigger]="null"
              (nzCollapsedChange)="onChangeCollapsed($event)">
      <nzo-navigation-bar
        [selectedUrl]="currentUrl"
        [menus]="menus"
        [collapsed]="isCollapsed"
        mode="vertical">
      </nzo-navigation-bar>
    </nz-sider>


  </ng-template>

  <nz-layout class="scrollx-class">
    <nz-header class="nzo-header" style="background-color: #001529 !important;"
               *ngIf="setting.layoutMode==='vertical'">
      <i class="trigger" nz-icon
         [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
         (click)="isCollapsed = !isCollapsed"
      ></i>
      <!--      <ul nz-menu nzTheme="dark" nzMode="horizontal" class="header-menu">-->
      <!--        <ng-container *ngFor="let item of headerNavList">-->
      <!--          <li nz-menu-item *xskPermShow="item.permissionCode"-->
      <!--              [value]="item.GroupID"-->
      <!--              [nzSelected]="currentSelectedGroup?.groupId==item.groupId"-->
      <!--              (click)="clickHeader(item)">{{item.groupName}}</li>-->
      <!--        </ng-container>-->
      <!--      </ul>-->
      <div class="logo"></div>
      <nzo-global-header></nzo-global-header>
    </nz-header>

    <nz-content [class.nzo-content]="setting.layoutMode==='horizontal'">
      <router-outlet></router-outlet>
    </nz-content>
    <!--    <nz-footer>-->
    <!--      <nzo-global-footer></nzo-global-footer>-->
    <!--    </nz-footer>-->
  </nz-layout>
  <!--  <nzo-setting-drawer [setting]="setting"></nzo-setting-drawer>-->
</nz-layout>
