import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputTrimDirective} from './input-trim/input-trim.directive';
import {InstistionTrimDirective} from './instistion-info/instistion-trim.directive';
import {CodeInputDirective} from './code-input/code-input.directive';

@NgModule({
  declarations: [
    InputTrimDirective,
    InstistionTrimDirective,
    CodeInputDirective],
  exports: [
    InputTrimDirective,
    InstistionTrimDirective,
    CodeInputDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectiveModule {
}
