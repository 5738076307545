import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'nzo-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.less']
})
export class TestComponent implements OnInit {

  constructor() {
  }

  test() {
  }

  ngOnInit(): void {
  }

}
