import { Component, Input, OnInit, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "@environment";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { InformedConsent } from "src/app/services/informed-consent";

@Component({
  selector: "xsk-privacy-modal",
  templateUrl: "./privacy-modal.component.html",
  styleUrls: ["./privacy-modal.component.less"],
})
export class PrivacyModalComponent implements OnInit {
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  // 接口获取到的知情同意书内容 显示
  paraContent: any = this.nzModalData.paraContent;
  buttons: any = this.nzModalData.paraContent;
  // 浏览状态判断
  isTouchBottom: boolean = false;
  // 多选框的状态
  checkAllFlag: boolean = false;
  observer: IntersectionObserver;
  constructor(
    private modal: NzModalRef,
    private sanitizer: DomSanitizer,
    private informedConsent: InformedConsent
  ) {}

  destroyModal(): void {
    console.log(11111);
    this.modal.destroy();
  }

  // angular钩子 Dom加载完毕
  ngAfterViewInit(): void {
    if (this.buttons.length > 0) {
      this.checkAllFlag = false;
    } else {
      this.checkAllFlag = true;
    }
    console.log("ngAfterViewInit中的时间", this.checkAllFlag);

    this.observer = new IntersectionObserver(
      (entries) => {
        // 在这里处理交叉观察到的元素状态
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log('目标元素已经进入视窗');
            this.isTouchBottom = true;
          } else {
            // console.log('目标元素已经离开视窗');
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    // 将需要观察的元素添加到观察列表中
    const target = document.querySelector("#test");
    this.observer.observe(target);
  }

  // 查看是否全选
  updateAllChecked(val: any) {
    console.log("查看是否全选", val, this.buttons);
    this.checkAllFlag = true;
    for (const item of this.buttons) {
      if (!item.isConsent) {
        this.checkAllFlag = false;
      }
    }
    //console.log('查看是否全选',this.checkAllFlag);
  }

  onClick(a: any) {
    console.log("点击", a, this.buttons);
    this.checkAllFlag = true;
    a.isConsent = !a.isConsent;
    for (const item of this.buttons) {
      if (!item.isConsent) {
        this.checkAllFlag = false;
      }
    }
  }

  ngOnInit(): void {
    this.informedConsent
      .GetActiveInformedConsent({
        platform: "DISTRIBUTOR",
      })
      .subscribe((r) => {
        // if (environment.hmr) {
        //   r.paraContent =
        //     "<p>颠三倒四多所</p><p>颠三倒四多所</p><p>颠三倒四多所</p>";
        //   r.buttons = [{ buttonText: "33飒飒", sort: 1 }];
        // }
        this.buttons = r.buttons;
        this.paraContent = this.sanitizer.bypassSecurityTrustHtml(
          r.paraContent
        );
      });
  }
}
