import { Injectable } from "@angular/core";
import CryptoJS from "crypto-js";
import { environment } from "@environment";
import JSEncrypt from "jsencrypt";
import { GlobalVariable } from "../config/variable";
const secret = "";
const appKey = "";

@Injectable({
  providedIn: "root",
})
export class CryptoService {
  globalVariable = GlobalVariable;
  constructor() {}

  // 获取随机数
  getRandom() {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0].toString();
  }

  // SHA256加密
  sha256(message: string) {
    return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
  }

  // base64加密
  base64(message: string) {
    const wordArray = CryptoJS.enc.Utf8.parse(message);
    return CryptoJS.enc.Base64.stringify(wordArray);
  }

  // 获取当前时间戳
  getTimeStamp() {
    return Date.parse(new Date().toString()).toString();
  }

  // 生GUID
  guid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c: any) => {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0;
      // tslint:disable-next-line:no-bitwise
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  // 生成头 authorization
  getHeader() {
    const random = this.getRandom();
    const timeStamp = this.getTimeStamp();
    const signature = this.sha256(random + timeStamp + secret);
    return this.base64(`${appKey}.${random}.${timeStamp}.${signature}`);
  }

  // AES加密
  aes(message: string) {
    return CryptoJS.AES.encrypt(message, CryptoJS.enc.Utf8.parse(secret), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  }

  getDateStamp() {
    return new Date().getTime();
  }

  // 使用rsa公钥加密
  rsaPublicEncrypt(message: string) {
    let encryptor = new JSEncrypt(); // 创建加密对象实例
    encryptor.setPublicKey(this.globalVariable.rsaPublickKey); //设置公钥
    let s: any = encryptor.encrypt(message);
    // console.log('--------encryptor----', s)
    return s;
  }

  stringToHex(str) {
    let hexString = "";
    for (let i = 0; i < str.length; i++) {
      let hexCharCode = str.charCodeAt(i).toString(16);
      hexString += hexCharCode;
    }
    return hexString;
  }
}
