import { Inject, Injectable } from "@angular/core";
import { ApiService, API_SERVICE } from "ng-upgrade-api";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(@Inject(API_SERVICE) private apiService: ApiService) {}

  Login(model: any): Observable<any> {
    return this.apiService.postJson(`User/LoginByPassword`, {
      body: model,
    });
  }

  LoginDms(model: any): Observable<any> {
    return this.apiService.postJson(`User/LoginByDmsPassword`, {
      body: model,
    });
  }
  ChangeDmsPwd(model: any): Observable<any> {
    return this.apiService.postJson(`User/ChangeDmsPassword`, {
      body: model,
    });
  }

  SendCategoryCode(model: any): Observable<any> {
    return this.apiService.postJson(`Category/SendCategoryCode`, {
      body: model,
    });
  }

  Logout(): Observable<any> {
    return this.apiService.getJson(`User/LogoutByPassword`);
  }

  // 保存知情同意书
  SaveUserAgreeRecordPost(model: any): Observable<any> {
    return this.apiService.postJson(`InformedConsent/SaveUserAgreeRecord`, {
      body: model,
    });
  }
}
