import { catchError, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { CryptoService } from "src/app/providers/crypto.service";
import { GlobalVariable } from "src/app/config/variable";

@Injectable({
  providedIn: "root",
})
export class Interceptor implements HttpInterceptor {
  globalVariable = GlobalVariable;
  constructor(private cryptoService: CryptoService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (localStorage.getItem("rsaPubKey")) {
      this.globalVariable.rsaPublickKey = localStorage.getItem("rsaPubKey");
    }
    if (sessionStorage.getItem("rsaPubKey1")) {
      this.globalVariable.rsaPublickKey = sessionStorage.getItem("rsaPubKey1");
    }
    if (this.globalVariable.rsaPublickKey) {
      sessionStorage.setItem("rsaPubKey1", this.globalVariable.rsaPublickKey);
      let account: any = JSON.parse(localStorage.getItem("acount") ?? "{}");
      let auth: string = `${this.cryptoService.getDateStamp()}+${this.cryptoService.getRandom()}+${localStorage.getItem(
        "token"
      )}+${account.loginName}`;
      let a: any = this.cryptoService.rsaPublicEncrypt(auth);
      req = req.clone({
        setHeaders: {
          Authorization: a,
        },
      });
    }
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          console.log("HttpResponse", event);
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          // 请求失败丢进service统一处理
          console.log("HttpErrorResponse", err);
        }
        return throwError(err);
      })
    );
  }
}
