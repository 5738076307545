import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { AccountService } from "../services/account.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { ActivatedRoute } from "@angular/router";
import { BasicService } from "../services/basic.service";
import { GlobalVariable } from "../config/variable";
import { CryptoService } from "../providers/crypto.service";
import { GeneralService } from "ng-upgrade-common";

@Component({
  selector: "nzo-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.less"],
})
export class LoginComponent implements OnInit {
  globalVariable = GlobalVariable;
  isLoading: boolean;
  validateForm: UntypedFormGroup;
  password: any;
  userName: any;
  category: any;
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private accountService: AccountService,
    public activatedRoute: ActivatedRoute,
    private nzMessageService: NzMessageService,
    private basicService: BasicService,
    private cryptoService: CryptoService,
    private generalService: GeneralService
  ) {}

  getCaptcha(e) {
    e.preventDefault();
    console.log("获取验证码");
    if (!this.userName) {
      this.nzMessageService.warning("请输入正确的账号");
      return;
    }
    this.accountService
      .SendCategoryCode({ userName: this.userName })
      .subscribe((res) => {
        this.nzMessageService.success("验证码已发送，请注意短信查收");
      });
  }

  // 登录
  submitForm(): void {
    // 获取rsa公钥
    this.basicService.getRSAPubKey().subscribe((r: any) => {
      this.globalVariable.rsaPublickKey = r;
      this.isLoading = true;
      // tslint:disable-next-line:forin
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
      if (!this.category) {
        this.nzMessageService.warning("请输入验证码");
        this.isLoading = false;
        return;
      }
      if (!this.validateForm.valid) {
        return;
      }
      // rsa加密
      let encryPassword = this.cryptoService.rsaPublicEncrypt(this.password);
      this.accountService
        .LoginDms({
          loginName: this.userName,
          password: this.cryptoService.stringToHex(encryPassword),
          categoryCode: this.category,
        })
        .subscribe(async (r: any) => {
          this.generalService.publishEvent("landingSuccess", {});
        });
    });
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
    });
    // this.createComponentModal()
  }
}
