<div>
  <router-outlet></router-outlet>
  <nz-spin nzTip="加载中..."
           class="loading"
           *ngIf="globalVariable.loadingIsShow"
           [nzSize]="'large'"
           [nzSpinning]="true"
           [nzDelay]="globalVariable.loadingDelay">
  </nz-spin>
</div>


