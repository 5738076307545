import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';
import {LayoutModule} from './layout/layout.module';
import {LoginModule} from './login/login.module';
import {ChangePwdModule} from './changepwd/changepwd.module';
import {ANT_ICONS} from '../ant-icons';
import {PipesModule} from './pipes/pipes.module';
import {DirectiveModule} from './directives/directive.module';
import {MomentModule} from 'ngx-moment';
import {ApiConfig, ApiModule} from 'ng-upgrade-api';
import {GLOBAL_STATE_LOCAL_STORAGE_PROVIDER, API_STATE_LOCAL_STORAGE_PROVIDER, XskBasisModule, INJECTION_TOKEN_ENVIRONMENT_WITH_BUILD} from 'ng-upgrade-basis'
import {environment} from '@environment';
import {TestModule} from './features/test/test.module';
// import {NgxAmapModule} from 'ngx-amap';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from 'src/interceptors/app.interceptor';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    XskBasisModule,
    ApiModule,
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    LoginModule,
    ChangePwdModule,
    MomentModule,
    PipesModule,
    DirectiveModule,
    NzSpinModule,
    TestModule,
    // NgxAmapModule.forRoot({
    //   apiKey: environment.variables.mapApiKey
    // }),
  ],
  providers: [
    {provide: NZ_I18N, useValue: zh_CN},
    {provide: NZ_ICONS, useValue: ANT_ICONS},
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: INJECTION_TOKEN_ENVIRONMENT_WITH_BUILD, useValue: environment },
    {provide: ApiConfig, useValue: {root: environment.apiRoot}},
    NzNotificationService,
    GLOBAL_STATE_LOCAL_STORAGE_PROVIDER, API_STATE_LOCAL_STORAGE_PROVIDER
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
