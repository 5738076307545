import { Component, Inject, OnInit, ViewContainerRef } from "@angular/core";
import { ApinfoService } from "./providers/apinfo.service";
import { GlobalVariable } from "./config/variable";
import {
  BootstrapComponentBase,
  INJECTION_TOKEN_ENVIRONMENT_WITH_BUILD,
  EnvironmentWithBuildInfo,
} from "ng-upgrade-basis";
import { GeneralService } from "ng-upgrade-common";
import { NzModalService } from "ng-zorro-antd/modal";
import { PrivacyModalComponent } from "./components/privacy-modal/privacy-modal.component";
import { InformedConsent } from "./services/informed-consent";
import { Router } from "@angular/router";
import { environment } from "@environment";
import { NoticeService } from "./providers/notice.service";

@Component({
  selector: "nzo-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent extends BootstrapComponentBase implements OnInit {
  globalVariable = GlobalVariable;
  versionNumber: "";

  constructor(
    @Inject(INJECTION_TOKEN_ENVIRONMENT_WITH_BUILD)
    envWithBuild: EnvironmentWithBuildInfo = null,
    generalService: GeneralService,
    private apinfoService: ApinfoService,
    private nzModalService: NzModalService,
    private informedConsent: InformedConsent,
    private router: Router,
    private noticeService: NoticeService
  ) {
    super(envWithBuild, generalService);

    this.generalService.subscribeEvent(["landingSuccess"], () => {
      console.log("landingSuccess11");
      this.createComponentModal();
    });
  }

  async createComponentModal() {
    this.informedConsent
      .GetActiveInformedConsent({
        platform: "DISTRIBUTOR",
      })
      .subscribe((r) => {
        // if (environment.hmr) {
        //   r.paraContent = "test";
        // }
        if (!r.paraContent || r.isConsent) {
          this.router.navigate(["layout"]);
          return;
        }
        this.versionNumber = r.versionNumber;
        const modal = this.nzModalService.create({
          nzTitle: "用户知情同意书",
          nzContent: PrivacyModalComponent,
          nzClosable: false,
          nzWidth: "70vw",
          nzData: {
            paraContent: r.paraContent,
            buttons: r.buttons,
          },
          nzFooter: [
            {
              label: "不同意",
              onClick: (componentInstance) => {
                this.informedConsent
                  .SaveUserAgreeRecord({
                    isConsent: false,
                    versionNumber: this.versionNumber,
                  })
                  .subscribe((r) => {
                    modal.destroy();
                  });
                // console.log("不同意", componentInstance);
                // modal.destroy();
              },
            },
            {
              label: "同意",
              onClick: (componentInstance) => {
                const instance = modal.getContentComponent();
                console.log("同意", componentInstance, instance);
                if (!componentInstance.isTouchBottom) {
                  this.noticeService.warning("请阅读完本内容");
                  return;
                }
                if (!componentInstance.checkAllFlag) {
                  this.noticeService.warning("请勾选本政策内容");
                  return;
                }
                this.informedConsent
                  .SaveUserAgreeRecord({
                    isConsent: true,
                    versionNumber: this.versionNumber,
                  })
                  .subscribe((r) => {
                    modal.destroy();
                    this.router.navigate(["layout"]);
                  });
              },
            },
          ],
        });
        // const instance = modal.getContentComponent();
        // modal.afterOpen.subscribe(() => console.log("[afterOpen] emitted!"));
        // modal.afterClose.subscribe((result) =>
        //   console.log("[afterClose] The result is:", result)
        // );
      });
  }

  ngOnInit() {
    this.apinfoService.getApiInfo();
  }
}
