import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {BasicLayoutComponent} from './basic-layout/basic-layout.component';
import {NavigationBarComponent} from './navigation-bar/navigation-bar.component';
import {nzoLayoutService} from './nzo-layout.service';
import {SettingDrawerComponent} from './setting-drawer/setting-drawer.component';
import {GlobalHeaderComponent} from './global-header/global-header.component';
import {GlobalFooterComponent} from './global-footer/global-footer.component';
import {ThemeColorComponent} from './theme-color/theme-color.component';
import {LevelsMenuComponent} from './levels-menu/levels-menu.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { XskBasisModule } from 'ng-upgrade-basis';

@NgModule({
  declarations: [
    BasicLayoutComponent,
    NavigationBarComponent,
    SettingDrawerComponent,
    GlobalHeaderComponent,
    GlobalFooterComponent,
    ThemeColorComponent,
    LevelsMenuComponent,
  ],
  imports: [
    XskBasisModule,
    NzLayoutModule,
    NzAvatarModule,
    NzBadgeModule,
    NzDropDownModule,
    NzIconModule,
    NzDrawerModule,
    NzToolTipModule,
    RouterModule,
    ScrollingModule,
    NzSpinModule,
    NzSkeletonModule
  ],
  providers: [
    nzoLayoutService,
    NzModalService
  ]
})
export class LayoutModule {
}
