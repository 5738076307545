import {
  DashboardOutline,
  TableOutline,
  FormOutline,
  WarningOutline,
  UserOutline,
  HighlightOutline,
  BellOutline,
  SettingOutline,
  SettingFill,
  LogoutOutline,
  GlobalOutline,
  GithubOutline,
  CopyrightOutline,
  ProfileOutline,
  CodeOutline,
  MenuFoldOutline,
  PlusOutline,
  CopyOutline,
  MenuUnfoldOutline,
  DingdingOutline,
  SearchOutline,
  CloseSquareFill,
  CloseSquareTwoTone,
  CloseSquareOutline,
  EditOutline,
  DeleteOutline
} from '@ant-design/icons-angular/icons';

export const ANT_ICONS = [
  DashboardOutline,
  TableOutline,
  FormOutline,
  WarningOutline,
  UserOutline,
  HighlightOutline,
  BellOutline,
  SettingOutline,
  SettingFill,
  LogoutOutline,
  GlobalOutline,
  GithubOutline,
  CopyrightOutline,
  ProfileOutline,
  CodeOutline,
  MenuFoldOutline,
  PlusOutline,
  CopyOutline,
  MenuUnfoldOutline,
  DingdingOutline,
  SearchOutline,
  CloseSquareFill,
  CloseSquareTwoTone,
  CloseSquareOutline,
  EditOutline,
  DeleteOutline
];
